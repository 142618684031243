
import { defineComponent } from "vue";
import { FilterMatchMode } from 'primevue/api';

import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ProgressSpinner from "primevue/progressspinner";

import Utils from "@/utility/utils";

export default defineComponent({
  name: "PayableRelatedAPItemsTab",
  components: {
    Card,
    DataTable,
    Column,
    Button,
    InputText,
    Calendar,
    ProgressSpinner,
  },

  data: () => ({
      isLoading: false,
      itemsPerPage: 25,
      filters: {
        ap_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sortableDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        vendor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        invoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      ap_items: [
        {
          "ap_id": "17202-6",
          "vendor": "1156",
          "po": "17202",
          "pack_slip": "47.17202",
          "ap_acct": "888-0700",
          "1099": "N",
          "accrual_amt": "924.40",
          "accrual_reg_id_items": [
            { "accrual_reg_id": "179270" },
            { "accrual_reg_id": "179271" }
          ],
          "disc_pct": "2.00",
          "co_code": "1",
          "li_items": [
            {
              "li": "2",
              "actual_cost": "4.6600",
              "description_items": [
                { "description": "Front Cover HSXXXX"}
              ],
              "amount_items": [
                { "amount": "503.28", "expense_acct": "000-0300" }
              ],
              "part": "16N0015-XXX",
              "orig_cost": "4.6600",
              "rec_qty": "108.00",
              "um": "EA"
            },
            {
              "li": "3",
              "actual_cost": "4.4800",
              "description_items": [
                { "description": "Rear Panel OUTSIDE HSXXXX" }
              ],
              "amount_items": [
                { "amount": "421.12", "expense_acct": "000-0300" }
              ],
              "part": "16N0027-XXX",
              "orig_cost": "4.4800",
              "rec_qty": "94.00",
              "um": "EA"
            }
          ],
          "terms_code": "2%-30",
          "status": "A",
          "stamp_date": "04/03/2001",
          "rec_date": "04/03/2001",
          "stamp_id": "RECEIPTS.P1",
          "type": "I"
        },
        {
          "ap_id": "6704-1",
          "vendor": "2",
          "po": "6704",
          "ap_acct": "888-0700",
          "1099": "N",
          "accrual_amt": "0.00",
          "accrual_reg_id_items": [
            { "accrual_reg_id": "178240" },
            { "accrual_reg_id": "178241" }
          ],
          "co_code": "1",
          "li_items": [
            {
              "li": "1",
              "description_items": [
                { "description": "Aluminum - raw" }
              ],
              "amount_items": [
                { "amount": "0.00", "expense_acct": "000-0300" }
              ],
              "part": "ALUM",
              "rec_qty": "3000.00",
              "price_per": "1"
            },
            {
              "li": "2",
              "description_items": [
                { "description": "Cap for model 10 wheel" }
              ],
              "amount_items": [
                { "amount": "0.00", "expense_acct": "000-0300" }
              ],
              "part": "CAP-10",
              "rec_qty": "700.00"
            },
            {
              "li": "3",
              "description_items": [
                { "description": "Box for finished wheel" }
              ],
              "amount_items": [
                { "amount": "0.00", "expense_acct": "000-0300" }
              ],
              "part": "B0X",
              "rec_qty": "1000.00"
            }
          ],
          "terms_code": "NET-30",
          "status": "A",
          "stamp_date": "02/07/2000",
          "rec_date": "02/07/2000",
          "stamp_id": "RECEIPTS.P1",
          "type": "I"
        },
        {
            "ap_id": "6814-1",
            "vendor": "2",
            "po": "6814",
            "pack_slip": "891-9901",
            "ap_acct": "000-0700",
            "1099": "N",
            "accrual_amt": "620.00",
            "accrual_reg_id_items": [
                {
                    "accrual_reg_id": "1201"
                },
                {
                    "accrual_reg_id": "1202"
                },
                {
                    "accrual_reg_id": "1203"
                }
            ],
            "co_code": "1",
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "1.4500",
                    "cost_group": "1",
                    "amount_items": [
                        {
                            "amount": "0.00",
                            "expense_acct": "000-0300"
                        },
                        {
                            "amount": "290.00",
                            "expense_acct": "000-0702"
                        }
                    ],
                    "description_items": [
                        {
                            "description": "0.750OD X 0.090WALL"
                        },
                        {
                            "description": "(.565ID)"
                        }
                    ],
                    "part": "15.20.21.38",
                    "orig_cost": "1.4500",
                    "rec_qty": "200.00",
                    "std_cost": "0.0000",
                    "um": "FT",
                    "std_lot_size": "1"
                },
                {
                    "li": "2",
                    "actual_cost": "1.6500",
                    "cost_group": "1",
                    "amount_items": [
                        {
                            "amount": "0.00",
                            "expense_acct": "000-0300"
                        },
                        {
                            "amount": "330.00",
                            "expense_acct": "000-0702"
                        }
                    ],
                    "description_items": [
                        {
                            "description": "0.625OD X 0.093WALL"
                        },
                        {
                            "description": "(.437ID)"
                        }
                    ],
                    "part": "15.20.08.38",
                    "orig_cost": "1.6500",
                    "rec_qty": "200.00",
                    "std_cost": "0.0000",
                    "um": "FT",
                    "std_lot_size": "1"
                }
            ],
            "terms_code": "NET-30",
            "status": "A",
            "stamp_date": "06/07/2012",
            "rec_date": "05/26/2012",
            "stamp_id": "RECEIPTS.P1",
            "type": "I"
        },
        {
            "ap_id": "1-15",
            "vendor": "1",
            "po": "1",
            "invoice": "85",
            "inv_date": "02/27/2006",
            "inv_amount": "85.00",
            "check_items": [
                {
                    "check": "56038",
                    "chk_date": "06/30/2021",
                    "check_amt": "85.00"
                },
                {
                    "check": "56038",
                    "chk_date": "07/15/2021",
                    "check_amt": "-85.00"
                }
            ],
            "due_date": "03/19/2006",
            "ap_acct": "888-0700",
            "amt_to_pay": "85.00",
            "1099": "N",
            "accrual_amt": "85.00",
            "1099_box": "12165",
            "accrual_reg_id_items": [
                {
                    "accrual_reg_id": "179581"
                },
                {
                    "accrual_reg_id": "179582"
                }
            ],
            "disc_pct": "1.00",
            "disc_amount": "0.85",
            "disc_acct": "999-000-0940",
            "date_to_pay": "03/19/2006",
            "balance": "85.00",
            "co_code": "1",
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "85.0000",
                    "description_items": [
                        {
                            "description": "Model 10 15x10 chrome"
                        },
                        {
                            "description": "wheel one piece"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "85.00",
                            "expense_acct": "888-0330"
                        }
                    ],
                    "part": "110-51-52-C",
                    "orig_cost": "85.0000",
                    "rec_qty": "1.00",
                    "um": "EA"
                }
            ],
            "terms_code": "1%-20",
            "status": "O",
            "stamp_date": "02/27/2006",
            "rec_date": "04/10/2002",
            "reg_date_items": [
                {
                    "reg_date": "02/27/2006",
                    "reg_invoice_amt": "85.00",
                    "reg_id": "180359ü180360"
                }
            ],
            "stamp_id": "CATHIE",
            "type": "I"
        },
        {
            "ap_id": "18657-1",
            "vendor": "465",
            "po": "18657",
            "pack_slip": "3090877",
            "invoice": "6344016",
            "inv_date": "03/10/1995",
            "inv_amount": "570.00",
            "due_date": "04/09/1995",
            "ap_acct": "888-0700",
            "amt_to_pay": "570.00",
            "1099": "N",
            "accrual_amt": "570.00",
            "disc_amount": "0.00",
            "disc_acct": "999-000-0940",
            "date_to_pay": "04/09/1995",
            "balance": "570.00",
            "co_code": "1",
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "0.2000",
                    "description_items": [
                        {
                            "description": "Sleeving 1/2\" SHRINK "
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "570.00",
                            "expense_acct": "000-0300"
                        }
                    ],
                    "part": "00W0001-050",
                    "orig_cost": "0.2000",
                    "rec_qty": "2850.00",
                    "um": "FT"
                }
            ],
            "terms_code": "NET-30",
            "status": "P",
            "stamp_date": "03/22/1995",
            "rec_date": "03/13/1995",
            "reg_date_items": [
                {
                    "reg_date": "03/10/1995",
                    "reg_invoice_amt": "570.00"
                }
            ],
            "stamp_id": "ARUNA",
            "type": "I"
        },
        {
            "ap_id": "21131-3",
            "vendor": "54",
            "po": "21131",
            "pack_slip": "010880",
            "ap_acct": "888-0700",
            "1099": "N",
            "accrual_amt": "225.00",
            "co_code": "1",
            "li_items": [
                {
                    "li": "2",
                    "actual_cost": "0.0040",
                    "description_items": [
                        {
                            "description": "NUT HEX #6-32 "
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "120.00",
                            "expense_acct": "000-0300"
                        }
                    ],
                    "part": "06H0004-000",
                    "orig_cost": "0.0040",
                    "rec_qty": "30000.00",
                    "um": "EA"
                },
                {
                    "li": "3",
                    "actual_cost": "0.0070",
                    "description_items": [
                        {
                            "description": "WASHER LOCK #10 "
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "105.00",
                            "expense_acct": "000-0300"
                        }
                    ],
                    "part": "10H0002-000",
                    "orig_cost": "0.0070",
                    "rec_qty": "15000.00",
                    "um": "EA"
                }
            ],
            "terms_code": "NET-30",
            "status": "A",
            "stamp_date": "03/30/1995",
            "rec_date": "03/30/1995",
            "stamp_id": "REC.P1",
            "type": "I"
        },
        {
            "ap_id": "9396",
            "vendor": "4",
            "invoice": "PREPAY",
            "inv_date": "08/18/2004",
            "inv_amount": "-1.00",
            "due_date": "08/18/2004",
            "ap_acct": "888-0700",
            "amt_to_pay": "-1.00",
            "1099": "N",
            "date_to_pay": "08/18/2004",
            "balance": "-1.00",
            "co_code": "1",
            "terms_code": "NET-30",
            "status": "O",
            "stamp_date": "08/18/2004",
            "misc_amount_items": [
                {
                    "misc_amount": "-1.00",
                    "misc_acct": "888-0700",
                    "misc_desc": "Prepay - Check# 3421"
                }
            ],
            "reg_date_items": [
                {
                    "reg_date": "08/18/2004",
                    "reg_invoice_amt": "-1.00"
                }
            ],
            "stamp_id": "CATHIE",
            "type": "P"
        },
        {
            "ap_id": "17260-4",
            "vendor": "914",
            "po": "17260",
            "ap_acct": "888-0700",
            "1099": "N",
            "accrual_amt": "397.00",
            "accrual_reg_id_items": [
                {
                    "accrual_reg_id": "179910"
                },
                {
                    "accrual_reg_id": "179911"
                },
                {
                    "accrual_reg_id": "179912"
                }
            ],
            "disc_pct": "10.00",
            "co_code": "1",
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "25.0000",
                    "description_items": [
                        {
                            "description": "Chassis"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "262.50",
                            "expense_acct": "888-0302"
                        },
                        {
                            "amount": "-12.50",
                            "expense_acct": "100-1127"
                        }
                    ],
                    "part": "10N0332-000",
                    "orig_cost": "25.0000",
                    "rec_qty": "10.00",
                    "std_cost": "26.2500",
                    "um": "EA",
                    "std_lot_size": "1"
                },
                {
                    "li": "2",
                    "actual_cost": "13.7000",
                    "description_items": [
                        {
                            "description": "Cover"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "148.37",
                            "expense_acct": "000-0300"
                        },
                        {
                            "amount": "-11.37",
                            "expense_acct": "100-1127"
                        }
                    ],
                    "part": "10N0333-000",
                    "orig_cost": "13.7000",
                    "rec_qty": "10.00",
                    "std_cost": "14.8370",
                    "um": "EA",
                    "std_lot_size": "1"
                },
                {
                    "li": "3",
                    "actual_cost": "1.0000",
                    "description_items": [
                        {
                            "description": "Conn ELCON PIN"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "10.00",
                            "expense_acct": "888-0330"
                        }
                    ],
                    "part": "10X0259-000",
                    "orig_cost": "1.0000",
                    "rec_qty": "10.00",
                    "um": "EA"
                }
            ],
            "terms_code": "NET-30",
            "status": "A",
            "stamp_date": "09/20/2002",
            "rec_date": "09/20/2002",
            "stamp_id": "RECEIPTS.P1",
            "type": "I"
        },
        {
            "ap_id": "6676-1",
            "vendor": "17",
            "po": "6676",
            "pack_slip": "1514165",
            "invoice": "77372",
            "inv_date": "08/13/1996",
            "inv_amount": "1240.00",
            "due_date": "09/12/1996",
            "ap_acct": "888-0700",
            "amt_to_pay": "1240.00",
            "1099": "N",
            "accrual_amt": "1220.00",
            "freight_amt": "10.00",
            "freight_acct": "999-100-1115",
            "disc_pct": "11.00",
            "disc_amount": "136.40",
            "disc_acct": "999-000-0940",
            "date_to_pay": "09/18/1996",
            "balance": "1240.00",
            "co_code": "1",
            "misc_amount_items": [
                {
                    "misc_amount": "10.00",
                    "misc_acct": "000-0300",
                    "misc_desc": "SETUP"
                }
            ],
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "10.0000",
                    "description_items": [
                        {
                            "description": "Fan 24 VDC BALL BEARING "
                        },
                        {
                            "description": "0.29 AMPS"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "1220.00",
                            "expense_acct": "000-0300"
                        }
                    ],
                    "part": "10B0003-000",
                    "orig_cost": "10.0000",
                    "rec_qty": "122.00",
                    "um": "EA"
                }
            ],
            "terms_code": "NET-30",
            "status": "O",
            "stamp_date": "08/13/1996",
            "rec_date": "08/13/1996",
            "reg_date_items": [
                {
                    "reg_date": "08/13/1996",
                    "reg_invoice_amt": "1240.00"
                }
            ],
            "stamp_id": "DEMO",
            "type": "I"
        },
        {
            "ap_id": "17306-4",
            "vendor": "1156",
            "po": "17306",
            "pack_slip": "3667",
            "ap_acct": "888-0700",
            "1099": "N",
            "accrual_amt": "783.20",
            "disc_pct": "2.00",
            "co_code": "1",
            "li_items": [
                {
                    "li": "7",
                    "actual_cost": "19.5800",
                    "description_items": [
                        {
                            "description": "Back Panel PS4 SATIN"
                        },
                        {
                            "description": "SILVER"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "783.20",
                            "expense_acct": "000-0300"
                        }
                    ],
                    "part": "11N0167-000",
                    "orig_cost": "19.5800",
                    "rec_qty": "40.00",
                    "um": "EA"
                }
            ],
            "terms_code": "2%-30",
            "status": "A",
            "stamp_date": "03/28/1995",
            "rec_date": "03/28/1995",
            "stamp_id": "REC.P1",
            "type": "I"
        },
        {
            "ap_id": "21201-3",
            "vendor": "1089",
            "po": "21201",
            "ap_acct": "888-0700",
            "1099": "N",
            "accrual_amt": "4350.00",
            "accrual_reg_id_items": [
                {
                    "accrual_reg_id": "179620"
                },
                {
                    "accrual_reg_id": "179621"
                },
                {
                    "accrual_reg_id": "179622"
                }
            ],
            "co_code": "1",
            "li_items": [
                {
                    "li": "1",
                    "actual_cost": "435.0000",
                    "description_items": [
                        {
                            "description": "HC15-C1270 CONTROL BOARD"
                        },
                        {
                            "description": "15V 100A  115V INPUT"
                        }
                    ],
                    "amount_items": [
                        {
                            "amount": "815.60",
                            "expense_acct": "000-0300"
                        },
                        {
                            "amount": "3534.40",
                            "expense_acct": "888-0702"
                        }
                    ],
                    "part": "10P0001-470",
                    "orig_cost": "435.0000",
                    "rec_qty": "10.00",
                    "std_cost": "81.5601",
                    "um": "EA",
                    "std_lot_size": "1"
                }
            ],
            "terms_code": "NET-60",
            "status": "A",
            "stamp_date": "04/25/2002",
            "rec_date": "04/25/2002",
            "stamp_id": "RECEIPTS.P1",
            "type": "I"
        }
    ]
  }),

  computed: {
    computedMiscTotal() {
      const items = [...this.ap_items];
      if (!items || items.length === 0) {
        return '$0.00';
      }
      return this.formatPrice(items.reduce((acc: number, item: any) => acc + parseFloat(item.amt_to_pay ?? '0'), 0).toString());
    },
  },

  created() {
    this.ap_items.forEach((item: any) => {
      item.sortableDate = item.inv_date && item.inv_date !== '' ? new Date(item.inv_date) : null;
    });
  },

  methods: {
    formatPrice(amt: string){
      if (!amt || parseInt(amt) === 0) {
        return '';
      } else {
        return Utils.formatPrice(amt);
      }
    },
    formatDate(date: string | Date): string {
      return Utils.formatDate(date);
    },
  },
});
